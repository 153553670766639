each decl in declarations 
    if decl.type == 'bitmap'
        | static const unsigned char !{decl.data.name}[]!{settings.progmem? ' U8X8_PROGMEM': ''} = {!{decl.data.value}};
        |
    else 
        | !{decl}
        |
| u8g2.clearBuffer();
| u8g2.setFontMode(1);
| u8g2.setBitmapMode(1);
|
- var pad = settings.wrap ? '    ' : ''
if settings.wrap
    | void draw(void) {
    |
- var font = ''
- var drawColorMode = 1;
each layer in layers 
    if layer.inverted 
        if drawColorMode != 2
            | !{pad}u8g2.setDrawColor(2);
            |
            - drawColorMode = 2;
    else
        if drawColorMode != 1
            | !{pad}u8g2.setDrawColor(1);
            |
            - drawColorMode = 1;
    case layer.type
        when 'dot'
            | @!{layer.uid};!{pad}u8g2.drawPixel(@x:!{layer.position[0]}, @y:!{layer.position[1]});
        when 'line'
            | @!{layer.uid};!{pad}u8g2.drawLine(@x1:!{layer.p1[0]}, @y1:!{layer.p1[1]}, @x2:!{layer.p2[0]}, @y2:!{layer.p2[1]});
        when 'rect'
            - var func = 'draw' + (layer.radius? 'R': '') + (layer.fill ? 'Box' : 'Frame')
            | @!{layer.uid};!{pad}u8g2.!{func}(@x:!{layer.position[0]}, @y:!{layer.position[1]}, @w:!{layer.size[0]}, @h:!{layer.size[1]}!{layer.radius? `, @r:${layer.radius}`: ''});
        when 'circle'
            - var func = layer.fill ? 'drawDisc' : 'drawCircle'
            | @!{layer.uid};!{pad}u8g2.!{func}(@x:!{layer.position[0] + layer.radius}, @y:!{layer.position[1] + layer.radius}, @r:!{layer.radius});
        when 'ellipse'
            - var func = layer.fill ? 'drawFilledEllipse' : 'drawEllipse'
            | @!{layer.uid};!{pad}u8g2.!{func}(@x:!{layer.position[0] + layer.rx}, @y:!{layer.position[1] + layer.ry}, @rx:!{layer.rx}, @ry:!{layer.ry});
        when 'string'
            if layer.font != font
                - font = layer.font
                | !{pad}u8g2.setFont(u8g2_font_!{layer.font}_tr);
                |
            | @!{layer.uid};!{pad}u8g2.drawStr(@x:!{layer.position[0]}, @y:!{layer.position[1]}, @text:"!{layer.text}");
        when 'paint'
        when 'icon'
            - var func = settings.progmem ? 'drawXBMP' : 'drawXBM'
            | @!{layer.uid};!{pad}u8g2.!{func}(@x:!{layer.position[0]}, @y:!{layer.position[1]}, @w:!{layer.size[0]}, @h:!{layer.size[1]}, @image:!{layer.imageName});
        default 
            |
            | // Unknown layer type !{layer.type}
            |
    |
    |
if settings.wrap
    | }
    |
| u8g2.sendBuffer();