each decl in declarations 
    if decl.type == 'bitmap'
        | static const uint8_t !{decl.data.name}[] = {!{decl.data.value}};
        |
    else 
        | !{decl}
        |
| u8g2_ClearBuffer(&u8g2); 
| u8g2_SetBitmapMode(&u8g2, 1);
| u8g2_SetFontMode(&u8g2, 1);
|
- var pad = settings.wrap ? '    ' : ''
if settings.wrap
    | void draw(void) {
    |
- var font = ''
- var drawColorMode = 1;
each layer in layers 
    if layer.inverted 
        if drawColorMode != 2
            | !{pad}u8g2_SetDrawColor(&u8g2, 2);
            |
            - drawColorMode = 2;
    else
        if drawColorMode != 1
            | !{pad}u8g2_SetDrawColor(&u8g2, 1);
            |
            - drawColorMode = 1;
    case layer.type
        when 'dot'
            | @!{layer.uid};!{pad}u8g2_DrawPixel(&u8g2, !{layer.position[0]}, !{layer.position[1]});
        when 'line'
            | @!{layer.uid};!{pad}u8g2_DrawLine(&u8g2, !{layer.p1[0]}, !{layer.p1[1]}, !{layer.p2[0]}, !{layer.p2[1]});
        when 'rect'
            - var func = 'Draw' + (layer.radius? 'R': '') + (layer.fill ? 'Box' : 'Frame')
            | @!{layer.uid};!{pad}u8g2_!{func}(&u8g2, !{layer.position[0]}, !{layer.position[1]}, !{layer.size[0]}, !{layer.size[1]}!{layer.radius? `, ${layer.radius}`: ''});
        when 'circle'
            - var func = layer.fill ? 'DrawDisc' : 'DrawCircle'
            | @!{layer.uid};!{pad}u8g2_!{func}(&u8g2, !{layer.position[0] + layer.radius}, !{layer.position[1] + layer.radius}, !{layer.radius});
        when 'ellipse'
            - var func = layer.fill ? 'DrawFilledEllipse' : 'DrawEllipse'
            | @!{layer.uid};!{pad}u8g2_!{func}(&u8g2, !{layer.position[0] + layer.rx}, !{layer.position[1] + layer.ry}, !{layer.rx}, !{layer.ry});
        when 'string'
            if layer.font != font
                - font = layer.font
                | !{pad}u8g2_SetFont(&u8g2, u8g2_font_!{layer.font}_tr);
                |
            | @!{layer.uid};!{pad}u8g2_DrawStr(&u8g2, !{layer.position[0]}, !{layer.position[1]}, "!{layer.text}");
        when 'paint'
        when 'icon'
            | @!{layer.uid};!{pad}u8g2_DrawXBM(&u8g2, !{layer.position[0]}, !{layer.position[1]}, !{layer.size[0]}, !{layer.size[1]}, !{layer.imageName});
        default 
            |
            | // Unknown layer type !{layer.type}
            |
    |
    |
if settings.wrap
    | }
    |
| u8g2_SendBuffer(&u8g2); 