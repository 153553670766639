import {Point} from './point';

export default [
    new Point(8, 8),
    new Point(12, 8),
    new Point(32, 8),
    new Point(48, 64),
    new Point(60, 32),
    new Point(64, 32),
    new Point(64, 48),
    new Point(64, 128),
    new Point(72, 40),
    new Point(84, 48),
    new Point(96, 16),
    new Point(96, 32),
    new Point(96, 39),
    new Point(96, 40),
    new Point(96, 65),
    new Point(96, 68),
    new Point(96, 96),
    new Point(100, 64),
    new Point(102, 64),
    new Point(122, 32),
    new Point(128, 32),
    new Point(128, 36),
    new Point(128, 64),
    new Point(128, 80),
    new Point(128, 96),
    new Point(128, 128),
    new Point(128, 160),
    new Point(144, 168),
    new Point(150, 32),
    new Point(160, 32),
    new Point(160, 80),
    new Point(160, 132),
    new Point(160, 160),
    new Point(172, 72),
    new Point(192, 32),
    new Point(192, 64),
    new Point(192, 96),
    new Point(200, 200),
    new Point(206, 36),
    new Point(240, 64),
    new Point(240, 128),
    new Point(240, 160),
    new Point(240, 240),
    new Point(250, 122),
    new Point(256, 128),
    new Point(256, 32),
    new Point(256, 64),
    new Point(280, 240),
    new Point(296, 128),
    new Point(320, 200),
    new Point(320, 240),
    new Point(400, 240)
];
